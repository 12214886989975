.top-header-dark{
    background-color: #000000;
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF !important;

    & p {
        font-size: 1.375rem;
        font-weight: 700;
        line-height: .875rem;
        letter-spacing: .1rem;
    }

    & small {
        text-transform: uppercase;
        font-size: .55em;
        letter-spacing: .1rem;
    }

    & .bg-come-here {
        letter-spacing: .25rem;
    }
}

@media (max-width: 620px) {
    .top-header-dark{
        display: block;

        & img {
            width: 75%;
        }
    }
}