
$primary:       #ff6600 !default
$primaryShadow: #fe340c !default

$secondary:     #ffC60A !default
$success: #5dc0a9 !default
$danger: #fe340c !default

$gray-100: #F2F2F2 !default

// Font Size
// $display-font-sizes: (  '1': '5rem', '2': '4.5rem',  '3': '4rem',  '4': '3.5rem',  '5': '3rem',  '6': '1.5rem')

$display-font-sizes: ( 1: 4rem,  2: 3.5rem,  3: 3rem,  4: 2.5rem,  5: 2rem,  6: 1.5rem) !default

$font-family: 'Trebuchet MS' !default

// BORDER RADIUS
$border-radius: 0.65rem !default

