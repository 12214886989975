// .jumbotron {
//     background: linear-gradient(to top, rgb(0, 0, 0, 0.85) 0%, rgba(0, 100, 150, 0) 45%);
// }

.nav-item span {
    cursor: pointer;
}

.nav-link.link-tour.nav-tour {
    padding: 0.5rem 0;

    & .btn {
        padding: 0;

        &:hover {
            color: #FF6600;
        }

        &:focus {
            box-shadow: none;
        }
    }

    & p {
        margin-bottom: 0;
        margin: 2.5px 0;
    }
}

.info-products .nav-link {
    font-weight: 500;
}

#navbar-destino .nav-pills li {
    margin: 0;
}

#highlights li {
    list-style: disc !important;
}

#details-product li {
    list-style: disc !important;
}

.btn-contact {
    width: 75%;
    transition: linear .2s;

    &:hover {
        color: #FFF;
    }
}

.btn-see-options {
    color: #FFF;
    cursor: pointer;

    &:hover {
        color: #FFF;
    }
}

@media (max-width: 480px) {
    .btn-see-options {
        margin: auto !important;
    }
}

.conteudo {
    position: relative;
    bottom: 125px;
}

#info-adicional p {
    word-break: break-word;
}

.accordion-rules {
    & h5 {
        font-size: 1rem;
        font-weight: 700 !important;
        text-decoration: underline;
        margin-bottom: 10px !important;
    }

    & li {
        list-style: disc;
    }

    & .accordion-body {

        & .card {
            background-color: transparent !important;
            padding: 0 !important;

            & .p-3 {
                padding: 1rem 0 0 !important;
            }

            & .top {
                margin-bottom: 2rem;

                & p {
                    margin-left: 0;
                }
            }

            & p {
                margin-bottom: 1rem;
                margin-left: 1.5rem;
            }

            & h6 {
                font-weight: 600;
                margin-bottom: 1rem;
            }

            & ul {
                margin-bottom: 1.5rem;

                & li {
                    list-style: inside;
                    margin-left: 1.5rem;
                    margin-bottom: .15rem;
                }
            }

            & .bottom {

                & p {
                    margin-left: 0;
                }

                & ul {
                    margin-bottom: 1.5rem;

                    & li {
                        list-style: none;
                        margin-left: 0;
                        margin-bottom: .15rem;
                    }
                }
            }
        }

        & span {
            font-weight: 700;
        }
    }
}

@keyframes piscapisca {
    0% {
        border-color: #FF6600;
        color: #FF6600;
    }

    25% {
        border-color: #FFFFFF;
        color: #FFFFFF;
    }

    50% {
        border-color: #FF6600;
        color: #FF6600;
    }

    75% {
        border-color: #FFFFFF;
        color: #FFFFFF;
    }

    100% {
        border-color: #FF6600;
        color: #FF6600;
    }
}

.bagde-sig {
    display: inline-flex;
    background-color: #FFFFFF;
    color: #FF6600;
    border-radius: 5px;
    border: 1px solid #FF6600;
    padding: 5px 16px 5px 16px;
    margin-bottom: 16px;
    align-items: center;
    gap: 0.5em;

    & h6 {
        font-weight: 500;
    }

    & svg {
        animation: piscapisca 1.5s infinite;
    }
}