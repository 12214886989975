.school-bondinho {
    width: 85%;
    margin: auto;
}

.exclusive-bonde {

    & li {
        list-style: initial;
        margin-left: 3.5rem;

    }
}

@media (max-width: 575px) {
    .exclusive-bonde {

        & li {
            list-style: initial;
            margin-left: 2.5rem;
    
        }
    }
}