.pix-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        width: 70%;
    }

    p{
        margin-top: 10px;
        text-align: center;
    }

    .pix-separator{
        position: relative;
        width: 100%;

        hr{
            width: 100%;
        }
        p{
            position: absolute;
            margin: 0;
            top: 9%;
            left: calc(50% - 34.5px);
            background-color: #fff;
            padding: 0px 20px;
            
        }
    }

    .pix-copy-paste{
        width: 100%;
        background-color: #5dc0a9;
        display: flex;
        flex-direction: column;
        align-items: center;

        #qrcodelink{
            max-width: 90%;
            margin: 0;
            word-wrap: break-word;
        }
    }
}