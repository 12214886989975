.modal-registration {
    display: flex;
    justify-content: center;
    width: 100%;

    & .modal-content {
        max-width: 512px;
        max-height: 640px;
        border-radius: 2.5px;
        border: none;
    }

    & .modal-header {
        padding: 0rem 1rem 1rem;
        //border-bottom: none;
    }

    & .modal-lg {
        width: 500px;
    }

    & .modal-header {
        display: block;
        padding: 35px 40px;
        border-bottom: none;

        & .nav {
            justify-content: center;
            padding: 0;

            & .nav-item {
                margin: auto;
                width: 50%;

                & button {
                    font-size: 1rem;
                    width: 100%;
                    text-align: left;

                    &.nav-link {
                        & span {
                            color: #162c5996;

                            &:hover {
                                color: #162c5996;
                                isolation: inherit;
                            }
                        }

                        &.active span {
                            color: #162C59;
                        }
                    }
                }

                /*                 &:nth-child(1) button {
                    border-left: none;
                }

                &:nth-child(2) button {
                    border-right: none;
                } */
            }
        }
    }

    & .nav-link {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:hover {
            border: 1px solid transparent !important;
            margin-bottom: -1px;
        }
    }
}

.modal-new-account {
    & h1 {
        color: #666666 !important;
        font-size: 2rem;
        font-weight: 600;
    }

    & h2 {
        color: #666666 !important;
        font-size: 2rem;
        font-weight: 600;
    }

    & label {
        color: #666666 !important;
        font-size: 15px;
        margin-bottom: 5px;
    }

    & .form-check {
        text-align: left;
        margin-top: 20px;
        font-size: 1rem;
    }

    & .form-floating label {
        font-size: 1.2rem;
        transition: all .3s;
    }

    & input {
        border-radius: 0;
    }

    & button {
        width: 100%;
        border-radius: 0;
        color: #FFF;
    }

    & .form-text {
        text-align: left;
        font-size: .8rem;
        display: block;
    }

    & .passEmail {
        font-size: 1rem;
        padding: 20px 20px 25px;
    }

    & .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-select~label {
        opacity: 0.65;
        transform: scale(0.6) translateY(-0.5rem) translateX(0.55rem);
    }
}

.btn-facebook {
    background-color: #3B5998;
}

.btn-google {
    background-color: #FFF;
    border: 1px solid #c4c4c4 !important;
    color: rgb(160, 160, 160) !important;
}

.forgot-link{
    font-size: 14px;
}

.btnFacebook {
    display: block !important;
    color: #fff !important;
    background-color: #3b5998 !important;
    width: 100% !important;
    height: 43px;
    font-size: 14px;
    i {
        margin-right: 10px;
    }
}

@media (max-width: 575px) {
    .modal-registration {
        display: flex;
        justify-content: center;
        width: 100%;

        & .modal-content {
            max-width: 100%;
            max-height: 640px;
            border-radius: 2.5px;
            border: none;
        }

        & .modal-lg {
            width: auto;
        }
    }
}

/* #navbarSupportedContent>ul>li:nth-child(3)>div>div>div>div:nth-child(1),
#navbarSupportedContent>ul>li:nth-child(3)>div>div>div>div:nth-child(2) {
    width: 100%;
} */

#navbarSupportedContent>ul>li:nth-child(3)>div>div>div>div:nth-child(2)>button {
    justify-content: center;

    & div {
        margin-right: 0 !important;
    }

    & span {
        text-transform: inherit;
        font-size: 14px;
    }
}

#navbarSupportedContent>ul>li:nth-child(3)>div>div>div>div:nth-child(1) {
    margin-bottom: 15px;
}

#navbarSupportedContent>ul>li:nth-child(3)>div>div>div>div:nth-child(1)>span {
    padding: 0;
}