.reservation-details {
    .modal-content .modal-body h5 {
        margin: 0;
    }

    .close {
        background-color: transparent;
        border: unset;
        font-size: 25px;
    }

    .modal-dialog.modal-lg {
        max-width: 1024px;
    }

    .modal-content {
        width: 100% !important;
        max-height: 996px;
    }
}

.my-reservations .nav-tabs {
    font-size: clamp(1rem, 2.5vw, 2rem);

    & button {
        padding: 1rem 3rem;
    }
}

@media (max-width: 767px) {
    .my-reservations .nav-tabs {
        & button {
            padding: 1rem 2rem;
        }
    }
}