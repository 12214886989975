.thumbnail-slider-wrap {
  margin: 15px 0 25px;
  height: 85px;
  max-height: 85px;
  overflow: hidden;
  bottom: 115px;
  position: relative;
}

.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
  width: 92px !important;
  margin: 0 2.5px;
  //margin-right: 9px;
  overflow: hidden;

  & img {
    width: 180% !important;
    position: relative;
    transform: translate(-35%, -5px);
  }
}

.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 100%;
}

.slick-next,
.slick-prev {
  z-index: 1;
  //background-color: #FFF !important;
  //border: 2px solid #F2F2F2 !important;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  transition: all .2s;
  top: 45%;

  &::before {
    border-radius: 50px;
    display: block;
  }

  &:hover:before {
    color: #FFF !important;
  }
}

.slick-next:before {
  content: url(../../../assets/icons/arrow-right.svg);
  font-size: 30px;
  font-weight: 700;
  line-height: 0;
  color: #e2e2e2 !important;
  margin-left: 3px;
}

.slick-prev:before {
  content: url(../../../assets/icons/arrow-left.svg);
  font-size: 30px;
  font-weight: 700;
  line-height: 0;
  color: #e2e2e2 !important;
  margin-right: 3px;
}

.slick-next {
  right: -10px;
}

.slick-prev {
  left: -10px;
}

//   .thumbnail-slider-wrap .slick-track {
//     width: 100% !important;
//   }

.destino .thumbnail-slider-wrap {

  & .slick-next,
  .slick-prev {
    display: none !important;
  }
}

///fixing thumbnail bug

/* .slick-list .slick-track {
  display: flex;
  //align-items: center;
} */

.thumbnail-slider-wrap .slick-track {
  display: flex;
  justify-content: center;
}

img.slick-slide-image {
  width: 100%;
  position: relative;
  //transform: translate(-10%, -10%);
}

.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  max-height: 100% !important;
}

@media (max-width: 1200px) {
  .destino img.slick-slide-image {
    transform: translate(-10%, 0);
    width: 111%;
    position: relative;
  }

}

@media (max-width: 480px) {
  .thumbnail-slider-wrap {
    height: 60px;
    max-height: 60px;
  }

  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 120% !important;
    transform: translate(-35%, -20px);
  }

  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
    width: 120px !important;
    margin: 0 3px;
    overflow: hidden;
  }
}

