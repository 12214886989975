.body-not-found {
    background-color: #FF6600;
}

.not-found {
    height: 100vh;
    width: 100vw;
    color: #FFF;
    font-size: 1.25rem;
}

.four-zero-four {
    font-size: 2rem;
}

.link-home {
    color: #FFF;
    &:hover {
        opacity: .8;
        color: #FFF;
    }
}