@import '../../assets/sass/variables';

.panel-hours {
    padding: 15px 4px 10px 4px !important;
    position: relative;
    background-color: #FFFFFF;
    top: 10px;
    box-shadow: 0 0 5px #8798ad;
    z-index: 1;
}

.hours-options {
    border: 1px solid #707070;
    text-align: center;
    padding: .5rem .25rem;
    border-radius: .25rem;
    line-height: .875rem;
    cursor: pointer;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: #FFFFFF !important;

        & p, small, span {
            color: #FFFFFF !important;
        }
    }

    & p {
        font-size: .85rem !important;
        font-weight: 700;
        margin-bottom: 0;
        color: #707070;
    }

    & small {
        font-size: .65rem !important;
        font-weight: 500;
        line-height: 0;
        color: #707070;
    }

    & span {
        display: block;
        font-size: .45rem !important;
        font-weight: 400;
        line-height: 1.5;
    }
}

.hours-options[aria-disabled="true"] {
    background-color: #70707040;
    pointer-events: none;
}

.hours-title {
    text-transform: uppercase;
    font-size: .75rem;
    padding-left: .25rem;
}

.size-icon-hours {
    font-size: 22px !important;
    width: 1rem;
    position: absolute;
}

.panel-dropdown-content {

    & .load {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        border: solid 3px $primary;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1.0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}