@import '../../assets/sass/_variables.sass';

.compliance {
    .bg-compliance {

        & .slick-next:before,
        .slick-prev:before {
            color: #FFF !important;
            background-color: #ff660050;
            width: 2rem;
        }

    }

    .btn-custom {
        padding: .5rem 1rem;
        width: 100%;
    }

    .btn:hover {
        color: #FFFFFF !important;
    }
}


@media (max-width: 480px) {
    .bg-compliance {

        & img {
            height: 400px;
        }
    }
}