@import "../../assets/sass/_variables.sass";

.page-static.bg-how-to-get {

    & .top {
        margin-bottom: 3rem;

        & p {
            margin-left: 0;
        }
    }

    & p {
        margin-bottom: 1rem;
        margin-left: 1.5rem;
    }

    & h6 {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    & ul {
        margin-bottom: 1.5rem;

        & li {
            list-style: inside;
            margin-left: 1.5rem;
            margin-bottom: .15rem;
        }
    }

    & .bottom {

        & p {
            margin-left: 0;
        }

        & ul {
            margin-bottom: 1.5rem;
    
            & li {
                list-style: none;
                margin-left: 0;
                margin-bottom: .15rem;
            }
        }
    }
    
}