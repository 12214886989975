@import "../../assets/sass/_variables.sass";

.header-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
}

#header.hide {
    opacity: 0;
}

#main-header>div nav.bg-header-affiliates {
    background-color: $primary;
}

#main-header>div nav.bg-header-affiliates .acessar a {
    background-color: $primary;
    color: #fff;
}

#main-header>div nav.bg-header-affiliates .acessar a svg {
    color: #fff !important;
}

.bg-header-affiliates svg:not(.keep-color) {
    color: #fff !important;
}

@media (max-width: 991.98px) {
    .bg-header-affiliates .navbar-brand img {
        width: clamp(160px, 15vw, 180px);
        margin: 5px 7.5px;
    }
}


@media (max-width: 767px) {
    .bg-header-affiliates .navbar-brand img {
        width: clamp(120px, 15vw, 180px);
        margin: 5px 7.5px;
    }

    .brand-parceiros {

        & img {
            max-width: 100% !important;
        }
    }

    .col-parceiros {
        max-width: 115px;
    }

    .acesso-parceiros {
        font-size: .75rem;
    }
}

@media (max-width: 360px) {

    .acesso-parceiros {
        font-size: .65rem;
    }
}