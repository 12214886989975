// .jumbotron{
//     background: linear-gradient(to top, rgb(0, 0, 0, 0.85) 0%, rgba(0,100,150,0) 45%);
// }

.nav-item span {
    cursor: pointer;
}

.bg-contact {
    & span {
        color: #000;
        opacity: 0.8;
    }
    & .btn-contact {
        background: #FFF;
        &:hover {
            background: #FF6600;
        }
    }
}

.nav-link.link-tour.nav-tour {
    padding: 0.5rem 0;
    & .btn {
        padding: 0;
        &:hover {
            color: #FF6600;
        }
        &:focus {
            box-shadow: none;
        }
    }
    & p {
        margin-bottom: 0;
        margin: 2.5px 0;
    }
}

.info-products .nav-link {
    font-weight: 500;
}

#navbar-destino .nav-pills li {
    margin: 0;
}

@media (max-width: 480px) {
    .btn-see-options {
        margin: auto !important;
    }
}

.conteudo {
    position: relative;
    bottom: 125px;
}

#navbar-destino {
    & svg {
        margin-right: 7.5px;
    }
}

.destino {
    & .accordion-item {
        border: none;
        border-radius: 0;
        padding: 0;
    }
    & .accordion-collapse {
        background-color: #F2F2F2;
        padding: .5rem 1rem;
    }
    & .accordion-button {
        padding: .5rem 1rem;
        &:focus {
            box-shadow: unset;
        }
        & span {
            margin-bottom: 0;
        }
    }
    & .accordion-body {
        padding: 0;

        & .card {
            background-color: transparent !important;
            padding: 0 !important;

            & .p-3 {
                padding: 1rem 0 0 !important;
            }

            & .top {
                margin-bottom: 2rem;
        
                & p {
                    margin-left: 0;
                }
            }
        
            & p {
                margin-bottom: 1rem;
                margin-left: 1.5rem;
            }
        
            & h6 {
                font-weight: 600;
                margin-bottom: 1rem;
            }
        
            & ul {
                margin-bottom: 1.5rem;
        
                & li {
                    list-style: inside;
                    margin-left: 1.5rem;
                    margin-bottom: .15rem;
                }
            }
        
            & .bottom {
        
                & p {
                    margin-left: 0;
                }
        
                & ul {
                    margin-bottom: 1.5rem;
            
                    & li {
                        list-style: none;
                        margin-left: 0;
                        margin-bottom: .15rem;
                    }
                }
            }
        }
    }
    & .accordion-button:not(.collapsed) {
        color: #000;
        background-color: #FFF;
        box-shadow: unset
    }
    & .accordion-item:last-of-type .accordion-collapse {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media(max-width: 414px) {
    #navbar-destino {
        font-size: 13px;
    }
}

#modalidades .preco {
    & .input-group {
        display: block;
    }
}



