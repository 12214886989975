@import "../../assets/sass/_variables.sass";

.cookies *{
    text-align: left;
    font-family: $font-family;
}

.cookies p{
    line-height: 1.5;
}

.cookies .card{
    margin-bottom: 10px;
}