.modal-confirm {
    & .modal-content {
        max-width: 520px;
        height: 720px;
        max-height: 680px;
        margin: auto;

        & .modal-body {
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            max-height: 100%;
            padding: 15px 40px;
        }
    }

    & .btn {
        min-width: 90px
    }
}
