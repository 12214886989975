.slider-img-park {
    height: 270px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.title-park {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.park-content {
    max-width: 990px;
    margin: auto;
}