
.panel-dropdown {
    position: relative;
    text-align: left;
    padding: .375rem .75rem;
    font-size: 14px;
    font-size: 0.875rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    //border: 1px solid #d2d8dd;
    //margin: 0 0 10px 0;
    & .fa-1x {
        font-size: 1.25rem;
        position: absolute;
    }
}

.panel-dropdown .panel-dropdown-content.right {
    left: auto;
    right: 0;
}

.panel-dropdown .panel-dropdown-content.active {
    opacity: 1;
    visibility: visible;
}

.panel-dropdown .panel-dropdown-content {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    position: absolute;
    top: 44px;
    left: 0px;
    z-index: 99;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 15px 15px 0 15px;
    white-space: normal;
    width: 325px;
}

.qtyButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.qtyButtons label {
    font-weight: 500;
    line-height: 1.25rem;
    padding-right: 5px;
    display: block;
    flex: 1;
    color: #162c59;
}
.qtyButtons label small {
    color: #495057 !important;
}
.price-dropdown {
    line-height: 8px;
    margin-top: .5rem;
    margin-bottom: 1.25rem;
}

.qtyInc, .qtyDec {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    background-color: #f2f2f2;
    -webkit-text-stroke: 1px #f2f2f2;
    color: #333;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}

.qtyButtons input, .qtyDec, .qtyInc {
    width: 30px;
    display: inline-block;
    text-align: center;
    border: none;
}

.qtyButtons input {
    outline: 0;
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    width: 30px;
    height: 36px !important;
    color: #162c59;
    line-height: 36px;
    margin: 0 !important;
    padding: 0 5px !important;
    border: none;
    box-shadow: none;
    pointer-events: none;
    display: inline-block;
    border: none !important;
}

.alert{
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 0%;
    padding: 0.75rem 1.25rem;

    p{
        margin: 0;
    }
}

.global.alert{
    position: fixed;
    right: 47%;
    top: 3%;
}

.danger.alert{
    /* background-color: #f8d7da; */
    color: #dc3545;
    background-color: #f8d7da;
    border-color: #f8d7da;
    transition: all 1s;
}

.info.alert{
    background-color: #2196F3;
    border-color: #2196F3;
}

.sucess.alert{
    background-color: #4CAF50;
    border-color: #4CAF50;
}

.warning.alert{
    background-color: #FFC107;
    border-color: #FFC107;
}


@media(max-width: 575px) {
    .rmdp-container {
        display: unset !important;
    }
}