#menuSideBar{
    left: -350px;
    transition: all .3s;
    width: unset;

    .custom-scroll{
        width: 350px;
        height: 100vh;
        filter: drop-shadow(2px 4px 6px rgba(0,0,0,.2));
    }

    button.x-close-button{
        background: transparent;
        right: 0;
        top: 0;
        position: absolute;
        margin: 0.5rem;
        border: 0;

    }

    .menu-options{
        padding: 1.5rem 2rem;
        //border-bottom: 1px solid #707070;

        ul{
            list-style: none;
        }

        li{
            margin-bottom: 1rem;
        }
        a{
            text-transform: uppercase;
            font-size: .875rem;
            font-weight: 700;
            text-decoration: none;
        }
    }
    #shadow{
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.3);
        z-index: -1;
        display: none;
    }
}