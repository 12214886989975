.terms-of-use *{
    text-align: left;
}

.terms-of-use .card{
    margin-bottom: 10px;
}

.terms-of-use ul{
    padding-left: 40px;
}