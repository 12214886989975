.navbar.navbar-expand.navbar-light .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 70%;
    top: -10px;
    position: relative;
}


.badge-primary {
    color: #fff;
    background-color: #162c59;
}

a#navbarDropdownCarrinho {
    position: relative;
}

div#number-circle {
    position: absolute;
}

button.p-0.m-0.text-muted {
    border: none;
    background-color: transparent;
}

.fas.fa-times {
    display: flex;
    justify-content: center;
    align-content: center;
}