@import "../../assets/sass/_variables.sass";

.terms-of-purchase *{
    font-family: $font-family;
}
.terms-of-purchase .card{
    margin-bottom: 10px;
}

.bg-terms-of-purchase {

    & h5 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 2rem 0 2rem;
    }

    & h6 {
        font-size: 1rem;
        font-weight: 600;
        margin: 2rem 0 2rem;
    }

    & ul {
        margin-left: 3rem;

        & li {
            list-style: disc;
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 767px) {
    .bg-terms-of-purchase {
        & ul {
            margin-left: 1rem;
    
            & li {
                list-style: disc;
                margin-bottom: 1rem;
            }
        }
    }
}