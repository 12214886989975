

#checkoutNav .nav-pills{
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
}

.nav-pills .nav-item {
    flex: 1 1 auto;
    text-align: center;
    max-width: 430px;
    width: 100%;
}

#checkoutNav .nav-pills .nav-link.active span.btn-circle, #catNav .nav-pills .nav-link.active span.btn-circle {
    background: #ff6600 !important;
    color: #fff !important;
}

#checkoutNav .nav-pills .nav-link, #catNav .nav-pills .nav-link {
    color: #fff !important;
    border-radius: 0;
    padding: 15px 15px 19px 15px;
    align-items: center;
}

#checkoutNav .nav-pills .nav-link, #catNav .nav-pills .nav-link {
    color: #fff !important;
    border-radius: 0;
    padding: 15px 15px 19px 15px;
    align-items: center;
}

#checkoutNav .btn-circle, #catNav .btn-circle {
    background: #fff;
}

.row.checkout-product-unit{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .close-btn{
        width: unset;
        position: absolute;

        button{
            background-color: transparent;
            border: unset;
        }
    }
}

.bg-custom-primary {
    background-color: #ff6600;
}

@media (max-width: 767px) {
    .row.checkout-product-unit{    
        .close-btn{
            padding-top: 12px;
        }
    }

    #checkoutNav .nav-pills .nav-link, #catNav .nav-pills .nav-link {
        color: #fff !important;
        border-radius: 0;
        padding: 10px 15px 10px 15px;
        align-items: center;
    }

    #checkoutNav .nav-pills .nav-link.active, #catNav .nav-pills .nav-link.active {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .bg-custom-primary {
        background-color: #F4F4F4;
    }
}