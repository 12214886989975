.programOng h1 {
    font-size: 26px;
    color: #2c3e50;
    margin-top: 20px;
}

.programOng h2 {
    font-size: 22px;
    color: #2c3e50;
    margin-top: 15px;
}

.programOng h3 {
    font-size: 13px;
    color: #2c3e50;
    margin-top: 10px;
}

.programOng .section {
    margin-bottom: 20px;
}

.programOng ul,
.programOng ol {
    margin-left: 20px;
    font-size: 12px;
}

.programOng table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 12px;
}

.programOng th,
.programOng td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.programOng .destaque {
    background-color: #f8f9fa;
    padding: 10px;
    border-left: 3px solid #3498db;
    margin: 10px 0;
    font-size: 12px;
}

.programOng a {
    color: #3498db;
    text-decoration: none;
}

.programOng a:hover {
    text-decoration: underline;
}

.programOng li {
    font-size: 14px !important;
}