@import "./../../../assets/sass/_variables.sass";


input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

.custom-control.custom-radio {
    display: flex;
    align-items: center;
}

.custom-control-input {
    margin-right: 5px;
    margin-bottom: 8px;
}

.custom-control small {
    font-size: 80%;
}

div#checkoutNav {
    background-color: #F4F4F4 !important;
}

.passport {
    top: 14px;
}

.passport.label-active {
    top: -12px;
}

.form-checkout {

    & .form-floating>label {
        padding: 0.5rem 0.75rem;
        opacity: .6;
    }

    & .form-floating>.form-control,
    .form-floating>.form-select,
    .form-select {
        height: calc(2.5rem + 2px);
        min-height: calc(2.5rem + 2px) !important;
        /* line-height: 2rem; */
        border-radius: 0;
    }

    & .form-floating>.form-control:focus,
    .form-floating>.form-control:not(:placeholder-shown) {
        padding-top: 0;
        padding-bottom: 0;
    }

    & .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-select~label {
        opacity: 0.85 !important;
        transform: scale(0.75) translateY(-2.2rem) translateX(-0.85rem);
        transition: .3s;

        &::after {
            background-color: transparent !important;
        }
    }
}

@media(min-width: 768px) and (max-width: 991px) {
    #asideCheck02>div>div.bg-white.p-3.mb-2.rounded>div>div:nth-child(1)>div>small>svg {
        margin-left: 10px !important;
    }
}

@media(max-width: 767px) {
    #asideCheck02>div>div.bg-white.p-3.mb-2.rounded>div>div:nth-child(1)>div>small>svg {
        margin-left: 30px !important;
    }
}

.modal-login div:nth-child(1) {
    display: inline-block;
}

.link-login {
    &:hover {
        opacity: .6;
    }
}

.step-login,
.login-container {
    & .new-account-login {
        padding-top: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #left-tabs-example-tabpane-stepData input[name="passport"] {
        font-size: .75rem;
        padding: 1rem 0.35rem !important;
    }
}