.receipt-alert {
    font-size: 1rem;
    text-align: center;
    padding: 0 50px 50px;
    background-color: #FFF;
}

@media (max-width: 992px) {
    .receipt-alert {
        text-align: center;
        padding: 0 25px 50px;
        background-color: #FFF;
    }
}

@media (max-width: 767px) {
    .receipt-alert {
        text-align: center;
        padding: 0 25px 50px;
        background-color: #FFF;
    }
}