@import "../../assets/sass/_variables.sass";

.fast-buy-resize {
    background-color: rgba($color: $primary, $alpha: 0.3);
    padding: 0 !important;

    .modal-dialog {
        max-width: unset;
        width: 1200px;

        .modal-content {
            border-radius: 12px;
            max-height: unset;
        }
    }

    .modal-header {
        border-bottom: none;
        position: absolute;
        right: 0;
    }
}

@media (max-width: 575px) {
    .fast-buy-resize .modal-dialog {
        max-width: unset;
        width: auto;
    }
}


.fast-buy-modal {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    max-height: 100%;
    min-height: 700px;

    .rmdp-container {
        width: 100%;
    }

    .qtyDec,
    .qtyInc {
        background-color: $primary;
        color: #fff;
        width: 1.875rem;
        height: 2.125rem;
        border-radius: 4px;
        //-webkit-text-stroke: 0;
        border: none;

        & span {
            font-size: 1.25rem !important;
            display: flex;
            justify-content: center;
        }
    }

    .calendar-input {
        border: none;
        border-bottom: 1px solid #707070;
        padding: 0.5rem 0.75rem 0.375rem 3em !important;
        font-size: 18px;
        color: #707070;
    }

    .modality-input {
        border: none;
        border-bottom: 1px solid #707070;
        padding: 0.5rem 0.75rem 0.375rem 0.375em !important;
        font-size: 18px;
        color: #707070;
    }

    .fast-buy-title {
        font-size: 23px;
        color: #707070;
        font-weight: 600;
        margin-bottom: 15px;
        width: 85%;
    }

    .fast-buy-content {
        display: flex;


        .fast-buy-description {
            //width: 40%;
            height: 100%;
            //border-right: 1px solid #707070;
            padding-right: 20px;
            text-align: justify;
            padding-bottom: 30px;
            overflow: auto;
            max-height: 600px;
            /* max-height: 350px; */

            p,
            span {
                color: #707070;
            }
        }

        .fast-buy-people {
            //width: 60%;
            padding: 5px 20px 20px;
            display: flex;
            flex-direction: column;
            //justify-content: space-between;
            border-left: 1px solid #707070;


            .panel-dropdown-content {

                .qtyButtons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 20px;


                    .flex-ajuste {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 200px;
                    }

                    .price {
                        text-align: right;
                        width: 180px;
                    }
                }

            }

            .buy-button {
                display: flex;
                justify-content: flex-end;

                button {
                    color: #fff;
                    padding: 0.2rem 5rem;
                    font-size: 22px;
                    border-radius: .65rem;
                }
            }
        }
    }

    .select-date {
        margin-bottom: 50px;
    }

    .subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;
    }

    .warning {
        background-color: #FF6600;
        opacity: 0.56;
        border-radius: 10px;
        margin-bottom: 45px;

        p {
            text-align: center;
            padding: 10px 5px;
            font-size: 13.5px;
            color: #FFF;
        }
    }

    .warning-custom {
        background-color: #FF6600;
        margin-bottom: 45px;

        p {
            text-align: center;
            padding: 10px 5px;
            font-size: 13.5px;
            color: #FFF;
        }
    }
}

.fast-buy-content {
    & .rmdp-container {
        position: relative;

        & .fa-calendar-alt {
            font-size: 22px;
        }

        & .fa-chevron-down {
            position: absolute;
            right: 0px;
            bottom: 10px;
            font-size: 24px;
        }
    }

    & .prices-modal {

        & label,
        h5,
        span {
            color: #707070 !important;
            font-size: 16px;
        }

        & small {
            color: #707070 !important;
            font-size: 11px;
        }

        & label,
        h5 {
            font-weight: 600;
            line-height: 1.5rem;
        }
    }

    & .qtyInc,
    .qtyDec {
        & span {
            color: #fff !important;
        }
    }

    & .qtyButtons input {
        width: 60px !important;
    }
}

.box-info {
    font-size: 0.875rem;
    color: #fff;
    background-color: #FF6600;
    text-align: center;
    padding: 10px 24px;
    margin-bottom: 10px;
    border-radius: 40px;
}

.attentionTour {
    padding-bottom: 1rem;
}

.link-rules-and-condictions {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
}

.link-rules-and-condictions:hover {
    opacity: .75;
}

@media (min-width: 1200px) {
    .ticket {

        & .subtotal.flex-ajuste {
            width: 100% !important;
        }

        & .flex-ajuste {
            width: 240px !important;
        }
    }

    .tour {

        & .subtotal.flex-ajuste {
            width: 100% !important;
        }

        & .flex-ajuste {
            width: 240px !important;
        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .ticket {

        & .subtotal.flex-ajuste {
            width: 100% !important;
        }

        & .flex-ajuste {
            width: 135px !important;
        }
    }

    .fast-buy-content .qtyButtons input {
        width: 45px !important;
    }
}

@media (max-width: 768px) {
    .ticket {

        & .subtotal.flex-ajuste {
            width: 100% !important;
        }

        & .flex-ajuste {
            width: 115px !important;
        }
    }
}

@media (max-width: 1200px) {
    .fast-buy-resize .modal-dialog .modal-content {
        width: 100%;
    }

    .fast-buy-resize .modal-dialog {
        max-width: unset;
        width: auto;
        margin: 0.5rem;
    }
}

@media (max-width: 768px) {
    .fast-buy-modal {
        max-height: 100%;
    }

    .fast-buy-content {
        & .qtyButtons input {
            width: 2rem !important;
        }
    }

    .fast-buy-modal .qtyDec,
    .fast-buy-modal .qtyInc {
        width: 1.75rem;
        height: 2rem;
    }

    .fast-buy-modal .fast-buy-content .fast-buy-people .panel-dropdown-content .qtyButtons .flex-ajuste {
        width: 120px;
    }

    .fast-buy-modal .fast-buy-content .fast-buy-people .panel-dropdown-content .qtyButtons .price {
        width: 100px;
    }
}

@media (max-width: 575px) {
    .fast-buy-modal {
        .rmdp-container {
            display: block !important;
        }

        .fast-buy-content {
            .fast-buy-people {
                border-left: none;
            }
        }
    }

    .fast-buy-modal .fast-buy-content .fast-buy-people .buy-button a {
        width: 100%;
    }

    .fast-buy-modal .fast-buy-content .fast-buy-people .buy-button {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 576px) {
    .fast-buy-content {

        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: #bdbdbd #ffffff;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 12px;
        }

        *::-webkit-scrollbar-track {
            background: #ffffff;
        }

        *::-webkit-scrollbar-thumb {
            background-color: #bdbdbd;
            border-radius: 10px;
            border: 3px solid #ffffff;
        }
    }
}

.flex-ajuste {
    & button {
        padding: 0;
        color: #707070 !important;
        font-size: 16px;
        font-weight: 600;
        text-align: start;

        &:focus {
            box-shadow: none;
        }
    }
}

.btn-slow-buy button.form-button {
    border-radius: 0.65rem;
    padding: 0.2rem 5rem !important;
}

.arraia-popup {
    text-align: left !important;
    & p {
        font-size: 1rem !important;
        margin-bottom: .5rem !important;
        line-height: 1rem !important;
    }
}

@media (max-width: 767px) {
    .arraia-popup {
        text-align: left !important;
        & p, b {
            font-size: .75rem !important;
            margin-bottom: .2rem !important;
            line-height: 1rem !important;
        }
    }
}

.discount-rules-slow-buy{
    h5{
        font-size: 16px;
    }
    p{
        font-size: 14px;
    }
}