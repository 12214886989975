@import './../../../assets/sass/_variables.sass';

.modal-exchange-policy {
    & .modal-content {
        max-height: 842px !important;
    }

    & .bg-exchange-policy {
        & h6 {
            font-size: 1rem;
            font-weight: 700;
            padding: 2rem 0 0;
            margin-bottom: 1rem;
        }
    }
}