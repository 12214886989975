// .bg-register-image {
//     background: url("../img/login/login-img-02.jpg");
//     background-position: center;
//     background-size: cover;
//   }
  
//   .bg-password-image {
//     background: url("../img/login/login-img-02.jpg");
//     background-position: center;
//     background-size: cover;
//   }

.login-container {
  max-width: 500px;
  padding: 40px;
  background-color: #fff;
  margin: 50px auto;
  border-radius: 2.5px;
}

.title-login {
    color: #666;
  }

  .link-login {
    & a {
        text-decoration: none;
        &:hover {
            color: #224abe;
            text-decoration: underline;
        }
    }
  }

  #random{  
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;  
  }
  
  .login-brand img {
    width: 80px;
  }
  
  .brand-text {
    font-size: 32px !important;
    font-weight: bold;
  }
  
  .split-screen .right, .split-screen .left {
      position: relative;
      min-height: 1px;
      padding: 0;
  }
  
  .valign {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
  }
  
  .split-screen .left .inner, .split-screen .right .inner {
      width: 520px;
      margin: 0 auto;
      padding: 40px;
      position: relative;
  }

  .expired-session .modal-body {
    margin: 50px 20px;
  }
  